const urlParams = new URLSearchParams(window.location.search);
const country = urlParams.get('country') || urlParams.get('Country');

export default {
  NodeEnv: process.env.NODE_ENV,
  ApiServerUrl: process.env.API_SERVER_URL,
  Port: process.env.PORT,
  GtmId: process.env.GTM_ID,
  GtagId: process.env.GTAG_ID,
  GoogleCid: process.env.GOOGLE_CID,
  Country: country || process.env.COUNTRY_CODE,
  CspScriptSrc: process.env.CSP_SCRIPT_SRC,
};
