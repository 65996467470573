/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {authHttp} from '@/apis/axiosHttp';
import apiHttp from '@/apis/axiosApi';
import toast from 'react-hot-toast';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import store, {setLeadData} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';
import {industries, islandLegal} from '@/components/utils/countrieslist';

type Props = {
  handleDone: ({key}) => void;
  aanvraag?: boolean;
};

type companyType = {
  dossier_number: string;
  establishment_number?: string;
  name: string;
  match_type?: string;
  establishment_city: string;
  establishment_street?: string;
  correspondence_city?: string;
  correspondence_street?: string;
  indication_economically_active?: boolean;
  checked?: boolean;
  founding_date?: string;
  company_type?: string;
  country?: string;
  house_number?: number | string;
  postcode?: number | string;
  establishment_date?: string;
  original?: {
    postcode?: number | string;
    house_number?: number | string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CompanyForm = ({handleDone, aanvraag}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const leadInfo = state.lead;
  const [checked, setChecked] = React.useState<boolean>(false);
  const [searchValid, setSearchValid] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searched, setSearched] = React.useState<boolean>(false);
  const [postCode, setPostCode] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');
  const [legalForm, setLegalForm] = React.useState<string>('');
  const [coCNumber, setCoCNumber] = React.useState<string>('');
  const [cribNumber, setCribNumber] = React.useState<string>('');
  const [streetName, setStreetName] = React.useState<string>('');
  const [houseNumber, setHouseNumber] = React.useState<string>('');
  const [district, setDistrict] = React.useState<string>('');
  const [neighbourhood, setNeighbourhood] = React.useState<string>('');
  const [houseNumberAddition, setHouseNumberAddition] =
    React.useState<string>('');
  const [codes, setCodes] = React.useState<string>(
    'Public limited liability company',
  );
  const [isCompanyDate, setIsCompanyDate] = React.useState<boolean>(true);
  const [nummer, setNummer] = React.useState<string>('');
  const [companyDate, setCompanyDate] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(1);
  const [pageLimit, setPageLimit] = React.useState<number>(1);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [toevoeg, setToevoeg] = React.useState<string>('');
  const [selected, setSelected] = React.useState<companyType>(null);
  const [companyList, setCompanyList] = React.useState<companyType[]>([]);
  const [country, setCountry] = React.useState<string>('');
  const [industry, setIndustry] = React.useState<string>('Ambulante handel');
  const today = new Date().toISOString().split('T')[0];
  const {translate} = useLocales();
  const handleSubmit = (skip: boolean) => {
    console.log(validated, 'test');
    if (!validated) {
      setIsSubmitting(true);
      return;
    } else {
      setIsSubmitting(false);
    }
    const toastId = toast.loading(translate('loading'));
    let payload = {};
    if (skip || !validated)
      payload = {
        Company: `${leadInfo?.FirstName} ${leadInfo?.LastName}`,
        Lead_Scoring__c: 'Medium',
      };
    else if (env.Country === 'NL')
      payload = {
        Company: selected?.name,
        Company_Postal_Code_Visiting__c: convertPostCode(postCode),
        Company_Street_Number_visiting__c: selected?.house_number,
        Company_City_visiting__c: selected?.establishment_city,
        Company_Street_Nr_Addition_visiting__c: toevoeg,
        Company_Country_visiting__c: selected.country,
        Company_street_visiting__c: selected.establishment_street,
        COC_Number__c: selected.dossier_number,
        CoC_Registration_date__c: selected?.founding_date ?
          formatDateToPattern(selected?.founding_date) :
          null,
        Lead_Scoring__c: 'Medium',
      };
    else
      payload = {
        Company: companyName,
        Company_Street_Number_visiting__c: houseNumber || nummer,
        Company_Street_Nr_Addition_visiting__c: houseNumberAddition,
        Company_Country_visiting__c: country || env.Country,
        CRIB_number__c: cribNumber,
        Company_Neighbourhood_District__c: neighbourhood || district,
        Company_street_visiting__c: streetName,
        COC_Number__c: coCNumber,
        CoC_Registration_date__c: companyDate ?
        formatDateToPattern(companyDate) :
        null,
        Lead_Scoring__c: 'Medium',
        Legal_Form__c: codes,
        Branche__c: industry,
      };
    authHttp
      .patch(`api/salesforce/lead/${store.getState().id}/`, {
        ...payload,
        ApplicationData__c: JSON.stringify(store.getState().lead),
      })
      .then(() => {
        handleDone({
          key: !aanvraag ? 3 : 2,
        });
        if (env.Country === 'NL')
          store.dispatch(
            setLeadData({
              ...store.getState().lead,
              ...payload,
              CoC_Registration_date__c: skip ?
                '' :
                formatDateToPattern(selected.founding_date),
              Company_type: skip ? 'StartingEntrepreneur' : selected.company_type ?
                selected.company_type : 'StartingEntrepreneur',
            }),
          );
        else
          store.dispatch(
            setLeadData({
              ...store.getState().lead,
              ...payload,
              CoC_Registration_date__c: skip ?
                '' :
                formatDateToPattern(selected.founding_date),
              Company_type: skip ? 'StartingEntrepreneur' : companyDate.toString() !== 'Invalid Date' ?
              isStartUp(companyDate) : 'StartingEntrepreneur',
            }),
          );
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        dataLayerHandler(skip);
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      })
      .finally(() => toast.dismiss());
  };

  const dataLayerHandler = (skip) => {
    const existingLoanInfo = JSON.parse(localStorage.getItem('loanInfo')) || {};
    const updatedDataLayer = {
      ...existingLoanInfo.dataLayer,
      stap_aanvraag: 'Bedrijfsgegevens',
      kvk: skip ? false : true,
    };
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: updatedDataLayer,
    };
    const updatedInfo = {
      ...existingLoanInfo,
      dataLayer: updatedDataLayer,
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(updatedInfo));
  };

  const handlePage = () => {
    if (page < pageLimit) {
      handleSearch(page + 1);
    }
  };

  const formatDateToPattern = (date) => {
    // Convert the Date object to ISO string (e.g., "2023-04-26T00:00:00.000Z")
    const newDate = new Date(date);
    let isoString;
    if (newDate && newDate.toString() !== 'Invalid Date')
      isoString = newDate?.toISOString();
    else return;
    // Extract the parts (year, month, day) from the ISO string
    const year = isoString.slice(0, 4);
    const month = isoString.slice(5, 7);
    const day = isoString.slice(8, 10);

    // Concatenate the parts with the desired pattern
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  React.useEffect(() => {
    const name = leadInfo.Company;
    if (name && env.Country === 'NL') {
        setSelected((prevSelected) => {
            const newSelected = {
                name: leadInfo.Company,
                establishment_city: leadInfo.City_visiting__c,
                dossier_number: leadInfo.COC_Number__c,
                establishment_street: leadInfo.Company_street_visiting__c,
                founding_date: leadInfo.CoC_Registration_date__c,
                company_type: leadInfo.Company_type || 'StartingEntrepreneur',
                country: leadInfo.Company_Country_visiting__c,
              house_number: leadInfo.Company_Street_Number_visiting__c,
                postcode: leadInfo.Company_Postal_Code_Visiting__c,
            };
            // Only update if there's a change
            if (JSON.stringify(prevSelected) !== JSON.stringify(newSelected)) {
                return newSelected;
            }
            return prevSelected;
        });
    } else {
        setCompanyName((prev) => leadInfo.Company !== prev ? leadInfo.Company : prev);
        setCribNumber((prev) => leadInfo.CRIB_number__c !== prev ? leadInfo.CRIB_number__c : prev);
        setStreetName((prev) => leadInfo.Company_street_visiting__c !== prev ? leadInfo.Company_street_visiting__c : prev);
        setHouseNumber((prev) => leadInfo.Company_Street_Number_visiting__c !== prev ? leadInfo.Company_Street_Number_visiting__c : prev);
        setHouseNumberAddition((prev) => leadInfo.Company_Street_Nr_Addition_visiting__c !== prev ? leadInfo.Company_Street_Nr_Addition_visiting__c : prev);
        setCoCNumber((prev) => leadInfo.COC_Number__c !== prev ? leadInfo.COC_Number__c : prev);
        setCompanyDate((prev) => leadInfo.CoC_Registration_date__c !== prev ? leadInfo.CoC_Registration_date__c : prev);
    }
}, [leadInfo, env.Country]);


  React.useEffect(() => {
    const payload = {
      Company_Postal_Code_Visiting__c: postCode,
      Company_Street_Number_visiting__c: nummer,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    if (postCode && nummer) setSearchValid(true);
  }, [postCode, nummer]);

  React.useEffect(() => {
    const payload = {
      Company_Street_Nr_Addition_visiting__c: toevoeg,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newToevoeg = toevoeg?.toUpperCase();
    setToevoeg(newToevoeg);
  }, [toevoeg]);

  const handleSearch = (paging) => {
    const toastId = toast.loading(translate('loading'));
    setSearched(true);
    setLoading(true);
    apiHttp
      .create('api/companyinfo/search/', {
        postcode: convertPostCode(postCode),
        house_number: nummer,
        house_number_addition: toevoeg,
        page: paging,
      })
      .then(({data}) => {
        handleDelete();
        setCompanyList(
          data.results.item.filter((el) => !!el.establishment_number),
        );
        setPage(data.paging.curpage);
        setPageLimit(data.paging.numpages);
        setLoading(false);
        setSelected(null);
        toast.dismiss(toastId);
      })
      .catch(() => {
        setLoading(false);
        setCompanyList([]);
        toast.error(translate('no_company_found'), {
          id: toastId,
        });
      });
  };

  const handleSelection = (select) => {
    const toastId = toast.loading(translate('loading'));
    setLoading(true);
    apiHttp
      .create('api/companyinfo/details/', {
        dossier_number: select.dossier_number,
        establishment_number: select.establishment_number || '',
      })
      .then(({data}) => {
        const foundingDate = new Date(
          data?.founding_date?.year,
          data?.founding_date?.month - 1,
          data?.founding_date?.day,
        );
        const establishmentDate = new Date(
          data?.establishment_date?.year,
          data?.establishment_date?.month - 1,
          data?.establishment_date?.day,
        );
        // // console.log(foundingDate, establishmentDate);
        setCountry(data?.establishment_address?.original?.country);
        setSelected({
          country: data?.establishment_address?.original?.country,
          ...select,
          house_number: data?.establishment_address?.original?.house_number,
                postcode: data?.establishment_address?.original?.postcode,
                founding_date:
            foundingDate.toString() !== 'Invalid Date' ?
              foundingDate :
              establishmentDate ?
              establishmentDate :
              '-',
          company_type: isStartUp(
            foundingDate.toString() !== 'Invalid Date' ?
              foundingDate :
              establishmentDate ?
              establishmentDate :
              '-',
          ),
        });
        setValidated(true);
        setLoading(false);
        toast.dismiss(toastId);
      })
      .catch(() => {
        toast.dismiss(toastId);
        setLoading(false);
      });
  };

  const isStartUp = (date) => {
    if (date) {
      // Subtract 6 months from the current date
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

      // Create a date to check (e.g., "2022-01-01")
      const dateToCheck = new Date(date);

      // Compare the date to the 6-months-ago date
      if (sixMonthsAgo < dateToCheck) {
        return 'StartingEntrepreneur';
      } else {
        return 'ExistingEntrepreneur';
      }
    } else {
      return 'StartingEntrepreneur';
    }
  };

  React.useEffect(() => {
    let payload = {};
    if (selected && env.Country === 'NL') {
      payload = {
        Company: selected.name,
        Company_City_visiting__c: selected.establishment_city,
        City_visiting__c: selected.establishment_city,
        Company_street_visiting__c: selected.establishment_street,
        COC_Number__c: selected.dossier_number,
        CoC_Registration_date__c: formatDateToPattern(selected.founding_date),
        Lead_Scoring__c: 'Medium',
        Company_type: selected.company_type || 'StartingEntrepreneur',
        Company_Country_visiting__c: selected.country,
        Company_Street_Number_visiting__c: selected.house_number,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    } else {
      console.log(validated, 'test2');
      if (companyName && houseNumber && codes && streetName) {
        setValidated(true);
      }

      payload = {
        Company: companyName,
        Company_Street_Number_visiting__c: houseNumber || nummer,
        Company_Street_Nr_Addition_visiting__c: houseNumberAddition,
        Company_Country_visiting__c: country,
        CRIB_number__c: cribNumber,
        Neighbourhood_District_Account__c: district,
        Neighbourhood_District_Contact__c: neighbourhood,
        Company_street_visiting__c: streetName,
        COC_Number__c: coCNumber,
        Lead_Scoring__c: 'Medium',
        CoC_Registration_date__c: companyDate,
        Legal_Form__c: codes,
        Company_type: isStartUp(companyDate) || 'StartingEntrepreneur',
        Branche__c: industry,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    }
  }, [
    coCNumber,
    companyName,
    country,
    codes,
    cribNumber,
    district,
    houseNumber,
    houseNumberAddition,
    neighbourhood,
    selected,
    streetName,
    industry,
    companyDate,
    nummer,
  ]);

  const formatDate = (date) =>
    new Date(date)
      .toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');

  const convertPostCode = (code) => {
    const oldRegex = /^\s*\d{4}(\s*-?\s*[A-Z]{2})\s*$/i;
    const newValue = code?.replace(oldRegex, (match) => {
      const trimmedValue = match.trim();
      const matches = trimmedValue.match(oldRegex);
      const digits = matches[0].match(/\d/g).join('');
      const letters = matches[0]
        .match(/[a-zA-Z]/g)
        .join('')
        .toUpperCase();
      return `${digits}${letters}`;
    });
    return newValue || '';
  };

  const seperatePostCode = (code) => {
    const oldRegex = /^\s*\d{4}(\s*-?\s*[A-Z]{2})\s*$/i;
    const newValue = code?.replace(oldRegex, (match) => {
      const trimmedValue = match.trim();
      const matches = trimmedValue.match(oldRegex);
      const digits = matches[0].match(/\d/g).join('');
      const letters = matches[0]
        .match(/[a-zA-Z]/g)
        .join('')
        .toUpperCase();
      return `${digits} ${letters}`;
    });
    return newValue || '';
  };

  const handleDelete = () => {
    setCountry('');
    setSelected((prev) => {
      return {
        ...prev,
        country: '',
        house_number: '',
        founding_date: '',
        company_type: '',
        dossier_number: '',
        name: '',
        postcode: '',
      };
});
    store.dispatch(setLeadData({
      ...store.getState().lead, Company: '',
      COC_Number__c: '',
    }));
    setChecked(false);
    setValidated(false);
    setIsSubmitting(true);
  };

  const handleDateChange = (value) => {
    const inputDate = new Date(value);
    const currentDate = new Date();
    const minDate = new Date(
      currentDate.getFullYear() - 100,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const maxDate = new Date(
      currentDate.getFullYear() + 0.1,
      currentDate.getMonth(),
      currentDate.getDate(),
    );

    if (inputDate < minDate || inputDate > maxDate) {
      setIsCompanyDate(false);
    } else {
      setIsCompanyDate(true);
    }
  };

  return (
    <Card.Body className="desktop_form">
     {env.Country === 'NL' && <p className="mt-4 mb-0 company_details-text">
        {translate('search_KVK_company')}
      </p>}
      <div className="px-1">
        {env.Country === 'NL' ? (
          <Row className="align-items-end mt-3">
            <Col xs={4} md={4}>
              <InputField
                isRequired={env.Country === 'NL'}
                isValidated={setValidated}
                handleChange={(e: string) => setPostCode(e)}
                label={translate('postcode')}
                type="postcode"
                defaultValue={postCode}
          isSubmitting={isSubmitting}
              />
            </Col>
            <Col xs={4} md={4}>
              <InputField
                isRequired={env.Country === 'NL'}
                handleChange={(e: string) => setNummer(e)}
                isValidated={setValidated}
                label={translate('house_number')}
                type="number"
                defaultValue={nummer}
          isSubmitting={isSubmitting}
              />
            </Col>
            <Col xs={4} md={4}>
              <InputField
                handleChange={(e: string) => setToevoeg(e)}
                label={translate('addition')}
                type="text"
                defaultValue={toevoeg}
              />
            </Col>
          </Row>
        ) : (
          <div>
            <InputField
              isRequired={env.Country === 'SXM' || env.Country === 'CUW'}
              isValidated={setValidated}
              handleChange={(e: string) => setCompanyName(e)}
              label={translate('company_name')}
              type="text"
              defaultValue={companyName}
          isSubmitting={isSubmitting}
            />
            <InputField
              isRequired={env.Country === 'SXM' || env.Country === 'CUW'}
              isValidated={setValidated}
              handleChange={(e: string) => setCoCNumber(e)}
              label={translate('CoC Number')}
              type="number"
              defaultValue={coCNumber}
          isSubmitting={isSubmitting}
            />
              <Form.Group controlId="validationCustom01">
            <FloatingLabel
              controlId="floatingInput"
              label={translate('company_start_date')}
              className="mb-3"
            >
              <input
                required
                type="date"
                value={companyDate}
                max={today}
                className={`${!isCompanyDate && companyDate ? 'is-invalid' : ''}
form-control`}
                pattern="\d{4}-\d{2}-\d{2}"
                placeholder={translate('company_start_date')}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDateChange(e.target.value)
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyDate(e.target.value)
                }
              />
            </FloatingLabel>
          </Form.Group>
            {env.Country === 'NL' && (
              <InputField
                isRequired
                isValidated={setValidated}
                handleChange={(e: string) => setLegalForm(e)}
                label={translate('legal_form')}
                type="text"
                defaultValue={legalForm}
          isSubmitting={isSubmitting}
              />
            )}
            {env.Country !== 'NL' && (
              <>
                <span className="mb-3 select-text">
                  {translate('legal_form')}
                </span>
                <Form.Select
                  className="full_select"
                  value={codes}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCodes(e.target.value)
                  }
                >
                  {islandLegal().map((legal, index) => (
                    <option value={legal} key={index}>
                      {legal}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}
            {env.Country !== 'NL' && (
              <>
                <span className="mb-3 select-text">
                  {translate('industry')}
                </span>
                <Form.Select
                  className="full_select"
                  value={industry}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setIndustry(e.target.value)
                  }
                >
                  {industries().map((indus, index) => (
                    <option value={indus.code} key={index}>
                      {indus.name}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}
            <InputField
              isRequired={env.Country === 'ABW'}
              isValidated={setValidated}
              handleChange={(e: string) => setCribNumber(e)}
              label={translate(env.Country === 'ABW' ? 'CRIB/Persoonsnummer' : 'CRIB number')}
              type="number"
              defaultValue={cribNumber}
          isSubmitting={isSubmitting}
            />
            <InputField
              isRequired
              isValidated={setValidated}
              handleChange={(e: string) => setStreetName(e)}
              label={translate('street_name')}
              type="text"
              defaultValue={streetName}
          isSubmitting={isSubmitting}
            />
            <InputField
              handleChange={(e: string) => setHouseNumber(e)}
              label={translate('house_number')}
              type="text"
              defaultValue={houseNumber}
          isSubmitting={isSubmitting}
            />
            <InputField
              handleChange={(e: string) => setHouseNumberAddition(e)}
              label={translate('house_number_addition')}
              type="text"
              defaultValue={houseNumberAddition}
          isSubmitting={isSubmitting}
            />
            {(env.Country === 'CUW' ||
              env.Country === 'ABW') && (
                <InputField
                  isRequired
                  isValidated={setValidated}
                  handleChange={(e: string) => setNeighbourhood(e)}
                  label={translate('Neighbourhood')}
                  type="text"
                  defaultValue={neighbourhood}
          isSubmitting={isSubmitting}
                />
              )}
            {(env.Country === 'SXM' ||
              env.Country === 'BES') && (
                <InputField
                  isRequired
                  isValidated={setValidated}
                  handleChange={(e: string) => setDistrict(e)}
                  label={translate('District')}
                  type="text"
                  defaultValue={district}
          isSubmitting={isSubmitting}
                />
              )}
            {env.Country === 'NL' && (
              <InputField
                isRequired
                isValidated={setValidated}
                handleChange={(e: string) => setCountry(e)}
                label={translate('country')}
                labelClasses="text-capitalize"
                type="text"
                defaultValue={country}
          isSubmitting={isSubmitting}
              />
            )}
          </div>
        )}
        {env.Country === 'NL' && (
          <div>
            <button
              disabled={!searchValid || loading}
              className="btn btn-primary
           w-100 mobile_button"
              onClick={() => handleSearch(1)}
            >
              {translate('search_KVK_register')}
            </button>
          </div>
        )}
        {searched && !loading && !selected?.dossier_number && (
          <div className="search_list">
            <div className="d-flex justify-content-between
                 align-items-center mt-4"
            >

            <div className="search_list__header">
              {translate('search_results')}
            </div>
              {page > 1 &&
                <div className="previous-button pr-3" onClick={() =>
                    handleSearch(page - 1)}
                >
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous')}
        </div>}
            </div>
            {companyList.length > 0 && (
              <div className="search_list__subheader">
                {translate('select_company')}
              </div>
            )}
            {companyList.length > 0 ? (
              <div>
                {companyList.map((element, index) => (
                  <div
                    className="search_list__lists"
                    key={index}
                    onClick={() => handleSelection(element)}
                  >
                    <Form.Group controlId="validationCustom05">
                      <Form.Check
                        required
                        type="checkbox"
                        id="default-checkbox"
                        checked={element.checked}
                        onChange={(e) => console.log(e)}
                        label={
                          <>
                            <Row
                      className="search_list__items justify-content-between
                    align-items-center m-0"
                            >
                      <Col xs={9} md={9} className="col-9 pl-0 item_name">
                        {element.name}
                      </Col>
                      <Col
                        xs={3}
                        md={3}
                        className="col-3 item_number
                      row justify-content-end"
                      >
                        {element.dossier_number}
                      </Col>
                    </Row>
                          </>
                        }
                      />
                    </Form.Group>
                  </div>
                ))}
                {page !== pageLimit && (
                  <button
                    disabled={loading}
                    className="cursor-pointer p-3 border-0 bg-white"
                    onClick={() => handlePage()}
                  >
                    {translate('more')}
                  </button>
                )}
              </div>
            ) : (
              <div>{translate('no_company_found')}</div>
            )}
          </div>
        )}

{env.Country === 'NL' && !selected?.dossier_number && (
          <Form.Group controlId="validationCustom05" className='mb-3 ml-4
          warning-checkbox'
          >
            <Form.Check
              type="checkbox"
              id="default-checkbox"
              checked={checked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setChecked(e.target.checked);
                setValidated(e.target.checked);
              }}
              label={translate('click_no_KVK')}
            />
          </Form.Group>
        )}
        {!!selected?.dossier_number && (
          <div className="mt-3">
            <div className="company_search">
              <Row className="align-items-center mb-3">
                <Col xs={6} md={6} className="col-6">
                  <span style={{color: '#5EC369'}}>
                    {translate('selected_comapany')}
                  </span>
                </Col>
                <Col xs={6} md={6} className="col-6 text-end">
                  <svg
                    className=" cursor-pointer"
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleDelete()}
                  >
                    <path
                      d="M5.27344 0.756958C5.46875 0.327271
                        5.89844 0.053833 6.36719 0.053833H11.0938C11.5625
                         0.053833 11.9922 0.327271 12.1875 0.756958L12.5
                          1.30383H16.25C16.9141 1.30383 17.5 1.88977 17.5
                           2.55383C17.5 3.25696 16.9141 3.80383 16.25
                            3.80383H1.25C0.546875 3.80383 0 3.25696 0
                             2.55383C0 1.88977 0.546875 1.30383 1.25
                              1.30383H5L5.27344 0.756958ZM15.3906
                               18.296C15.3516 19.3116 14.5312 20.0538
                                13.5156 20.0538H3.94531C2.92969 20.0538
                                 2.10938 19.3116 2.07031 18.296L1.21094
                                  5.05383H16.25L15.3906 18.296Z"
                      fill="#002172"
                    />
                  </svg>
                </Col>
              </Row>
              <h5>{selected.name}</h5>
              <h5>{`${selected.establishment_street} ${selected.house_number}`}</h5>
              <h5>{seperatePostCode(selected.postcode)} {selected.establishment_city}
              </h5>
            </div>
            <div className="mt-3 company_search_details">
              <Row className="align-items-center mb-3">
                <Col xs={7} md={7} className="col-7">
                  <span>{translate('registered_since')}</span>
                </Col>
                <Col xs={5} md={5} className="col-5 text-end">
                  <span>
                    {formatDate(
                      selected?.founding_date || selected?.establishment_date,
                    )}
                  </span>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col xs={7} md={7} className="col-7">
                  <span>{translate('kvk_nr')}</span>
                </Col>
                <Col xs={5} md={5} className="col-5 text-end">
                  <span>{selected?.dossier_number}</span>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <Row className="align-items-end m-0">
          <Col xs={6} md={6} className="p-0"></Col>
          <Col xs={6} md={6}
            className="justify-content-end text-end p-0 desktop-submit-btn"
          >
            <div className="row m-0 justify-content-end">
              <button
                type="submit"
                className={`btn ${!validated ?
                  'btn-disabled' : 'btn-success'}
                px-3 mobile_button`}
              onClick={() => handleSubmit(checked)}
              >
                {translate('next_step')}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Card.Body>
  );
};

export default CompanyForm;
