/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import * as React from 'react';
import {useMediaQuery} from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Confetti from '@/assets/images/animation_640_ldoac1of.gif';
import DesktopCreditForm from '@/views/desktop/CreditForm';
import DesktopDocumentsForm from '@/views/desktop/DocumentsForm';
import MobileDocumentsForm from '@/views/mobile/DocumentsForm';
import DesktopContactForm from '@/views/desktop/ContactForm';
import MobileContactForm from '@/views/mobile/ContactForm';
import DesktopCompanyForm from '@/views/desktop/CompanyForm';
import DesktopPersonForm from '@/views/desktop/PersonForm';
import DesktopSpouseForm from '@/views/desktop/SpouseForm';
import BusinessPartnerForm from '@/views/desktop/BusinessPartnerForm';
import MobileBusinessForm from '@/views/mobile/BusinessPartnerForm';
import MobileCompanyForm from '@/views/mobile/CompanyForm';
import MobileCreditForm from '@/views/mobile/CreditForm';
import MobilePersonForm from '@/views/mobile/PersonForm';
import MobileSpouseForm from '@/views/mobile/SpouseForm';
import Collapse from 'react-bootstrap/Collapse';
import Placeholder from 'react-bootstrap/Placeholder';
import store, {setCredit, setFinalStep, setLeadData, resetState} from '@/store';
import CheckIcon from '@/assets/images/footer_check.svg';
// import apiHttp from '@/apis/axiosApi';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import useLocales from '@/hooks/useLocales';
import {install} from 'ga-gtag';
import env from '@/constants/env';
import {findCallNumByEnv, findCountryByEnv} from '@/components/utils/helper';
import TagManager from 'react-gtm-module';
import PhoneIcon from '@/assets/images/phone_footer.svg';
import ChatIcon from '@/assets/images/chat_footer.svg';

type CustomToggleProps = {
  eventKey: number;
  activeKey: number;
  children: React.ReactNode;
  decoratedOnClick: React.MouseEventHandler<HTMLDivElement>;
  completed: number;
  isValid?: boolean;
};

type handleDoneProps = {
  loan?: number;
  year?: number;
  key: number;
};

type getDoneProps = {
  loan: number;
  year: number;
  interestRate?: number;
};

type FileProps = {
  file?: string;
  versionId?: string;
  Id: string;
  Name?: string;
  Body?: string;
  isRequired?: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CustomToggle = ({
  children,
  eventKey,
  activeKey,
  decoratedOnClick,
  completed,
  isValid,
}: CustomToggleProps) => {
  const isCurrentEventKey = activeKey === eventKey;
  const isReady = completed - Number(eventKey);

  install(env.GtagId);

  return (
    <div
      className={`cursor-pointer ${
        isReady >= 0 && !isValid ?
          'accordion_header_normal' :
          'accordion_header_disabled'
      }`}
      onClick={isReady >= 0 && !isValid ? decoratedOnClick : null}
    >
      <div className="checked_row">
        <div className='svg-width'>
        {completed > eventKey && (
          <svg
            width="20"
            height="21"
            style={{marginRight: '7px'}}
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.49219 14.1527C9.0625 14.5824 8.39844 14.5824
            7.96875 14.1527L5.46875 11.6527C5.03906 11.223 5.03906
              10.559 5.46875 10.1293C5.89844 9.69958 6.5625 9.69958
              6.99219 10.1293L8.75 11.848L12.9688 7.62927C13.3984
                7.19958 14.0625 7.19958 14.4922 7.62927C14.9219 8.05896
                14.9219 8.72302 14.4922 9.15271L9.49219 14.1527ZM20
                  10.8715C20 16.4183 15.5078 20.8715 10 20.8715C4.45312
                  20.8715 0 16.4183 0 10.8715C0 5.36365 4.45312 0.87146
                    10 0.87146C15.5078 0.87146 20 5.36365 20 10.8715ZM10
                    2.74646C5.50781 2.74646 1.875 6.41833 1.875 10.8715C1.875
                      15.3636 5.50781 18.9965 10 18.9965C14.4531 18.9965 18.125
                      15.3636 18.125 10.8715C18.125 6.41833 14.4531 2.74646
                        10 2.74646Z"
              fill="#5EC369"
            />
          </svg>
        )}
        </div>
        <div>{children}</div>
      </div>
      <div className="mr-2 text-right">
        {isCurrentEventKey ? (
          <svg
            width="25"
            height="17"
            viewBox="0 0 25 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.997"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25 12.7309C25 12.7267 25 12.7225
             25 12.7182C21.4644 8.77149 17.9303 4.82219 14.3977 0.870328C13.9247 0.360328 13.3603 0.0701341
              12.7045 -4.39584e-07C12.5568 -4.39584e-07 12.4091 -4.39584e-07 12.2614 -4.39584e-07C11.6619
               0.0641625 11.1354 0.316113 10.6818 0.755979C7.12619 4.73783 3.56559 8.71254 0 12.6801C0
                12.7098 0 12.7394 0 12.7691C1.26523 14.1837 2.53417 15.594 3.80682 17C6.66083 13.8153 9.5131
                 10.6284 12.3636 7.43909C12.4069 7.40123 12.4486 7.361 12.4886 7.31839C15.3767 10.5412 18.2631
                  13.7663 21.1477 16.9936C21.1591 17.0021 21.1705 17.0021 21.1818 16.9936C22.4531 15.5702
                   23.7258 14.1493 25 12.7309Z"
              fill="#002172"
              stroke="#002172"
            />
          </svg>
        ) : (
          // <svg width="25" height="16" viewBox="0 0 25 16" fill="none"
          //   xmlns="http://www.w3.org/2000/svg"
          // >
          //   <path d="M4.82111 1.14656L4.46711 0.792454L4.1135 1.14695L1.646
          //  3.62063L1.29378 3.97374L1.646 4.32686L12.146 14.8532L12.5
          //   15.2081L12.854 14.8532L23.354 4.32686L23.7062 3.97374L23.354
          //    3.62063L20.8865 1.14695L20.5329 0.792454L20.1789 1.14656L12.5
          //     8.82793L4.82111 1.14656Z" fill="#002172" stroke="#002172"
          //   />
          // </svg>
          <svg
            width="25"
            height="17"
            viewBox="0 0 25 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.997"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25 4.26906C25 4.27329 25 4.27753
           25 4.28176C21.4644 8.22851 17.9303 12.1778 14.3977 16.1297C13.9247 16.6397 13.3603 16.9299 12.7045
            17C12.5568 17 12.4091 17 12.2614 17C11.6619 16.9358 11.1354 16.6839 10.6818 16.244C7.12619 12.2622
             3.56559 8.28746 0 4.31988C0 4.29024 0 4.26058 0 4.23094C1.26523 2.81631 2.53417 1.40599 3.80682
              3.19963e-08C6.66083 3.18468 9.5131 6.37165 12.3636 9.56091C12.4069 9.59877 12.4486 9.639 12.4886
               9.68161C15.3767 6.45882 18.2631 3.23374 21.1477 0.0063528C21.1591 -0.0021176 21.1705 -0.0021176
                21.1818 0.0063528C22.4531 1.42982 23.7258 2.85071 25 4.26906Z"
              fill="#002172"
              stroke="#002172"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const [checked, setChecked] = React.useState<boolean>(false);
  const [completed, setCompleted] = React.useState<number>(0);
  const [activeKey, setActiveKey] = React.useState<number>(0);
  const state = useSelector((state: stateType) => state);
  const {metaData, lead, selectedProduct} = useSelector(
    ({metaData, lead, selectedProduct}: stateType) => {
      return {metaData, lead, selectedProduct};
    },
  );
  const leadInfo = state.lead;
  const [year, setYear] = React.useState<number>(0);
  const [loan, setLoan] = React.useState<number>(0);
  const [listFiles, setListFiles] = React.useState<FileProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setLoanMoney] = React.useState<number>(0);
  const [loanInterest, setLoanInterest] = React.useState<number>(0);
  const [monthlyPayment, setMonthlyPayment] = React.useState<number>(0);
  const [aanvraag, setAanvraag] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>('');
  const isMobile = useMediaQuery({query: '(max-width: 700px)'});
  const isTablet = useMediaQuery({query: '(max-width: 1224px)'});
  const [interestRate, setInterestRate] = React.useState<number>(8.75);
  const [isUpdated, setIsUpdated] = React.useState<boolean>(false);
  const handleDone = (done: number) => {
    setCompleted(done > completed ? done : completed);
    setActiveKey(done);
  };
  const {translate} = useLocales();

  React.useEffect(() => {
    if (activeKey === 6 && !isUpdated) {
      fetchListFiles();
    }
    if (activeKey !== 6 && isUpdated) {
      setIsUpdated(false);
    }
  }, [activeKey]);

  const fetchListFiles = () => {
      const companyFiles = metaData.filter(
        (el) => el.Label === lead.Company_type,
        );
        console.log(lead.Company_type, companyFiles);
      const choosenProduct = selectedProduct;
      const docsData = state.docs;
      let files = [];
      if (companyFiles[0]?.AdditionalDocuments__c) {
        files = Object.entries(
          JSON.parse(companyFiles[0]?.AdditionalDocuments__c),
        ).map(([label, value]) => ({
          file: null,
          versionId: '',
          Id: companyFiles[0]?.Id,
          Name: '',
          isRequired: value === 0 ? false : true,
          Body: label,
        }));
        if (choosenProduct?.AdditionalDocuments__c) {
          const labelValueArray = Object.entries(
            JSON.parse(choosenProduct?.AdditionalDocuments__c),
          ).map(([label, value]) => ({
            file: null,
            versionId: '',
            Id: companyFiles[0]?.Id,
            Name: '',
            isRequired: value === 0 ? false : true,
            Body: label,
          }));
          files.push(...labelValueArray);
        }
        if (files.length > 0) {
          const updatedFiles = files.map((file) => {
            const matchingBody = docsData?.find(
              (doc) => doc?.Title?.indexOf(file?.Body) !== -1,
            );
            if (matchingBody) {
              return {
                ...file, ...matchingBody,
                file: matchingBody.Title,
              };
            }
            return file;
          });
          setListFiles([...updatedFiles]);
          setIsUpdated(!isUpdated);
        }
      }
  };

  React.useEffect(() => {
    const interest = interestRate / 100 / 12;
    const months = year * 12;
    setLoanInterest(Number((loan * interest).toFixed(2))); // amount of interest per year
    const x = Math.pow(1 + interest, months); // monthly figure
    setMonthlyPayment((loan * x * interest) / (x - 1));
    // eslint-disable-next-line spaced-comment
    setLoanMoney(Number(monthlyPayment * months - loan)); // total amount to be paid
  }, [year, loan, interestRate, loanInterest, monthlyPayment]);

  const handleDesktopForm = ({year, loan, key}: handleDoneProps) => {
    if (year && loan) {
      setYear(year);
      setLoan(loan);
    }
    handleDone(key);
    console.log(key);
    const eleNumber =
      env.Country !== 'NL' && !leadInfo.Married__c && key >= 5 ? key + 1 : key;
    const element = document.getElementById(`acc-${eleNumber}`);
    if (key < 7) {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleGetData = (load: getDoneProps) => {
    setYear((prevState) => {
      if (prevState !== load?.year) {
        return load?.year;
      }
      return prevState;
    });
    setLoan((prevState) => {
      if (prevState !== load?.loan) {
        return load?.loan;
      }
      return prevState;
    });
    setInterestRate((prevState) => {
      if (prevState !== load?.interestRate) {
        return load?.interestRate;
      }
      return prevState;
    });
    store.dispatch(setCredit({...load}));
  };

  const selectAccord = (event: number) => {
    if (event === activeKey) setActiveKey(null);
    else {
      const eleNumber =
        env.Country !== 'NL' && leadInfo.Married__c && event >= 5 ?
          event + 2 :
          event + 1;
      const element = document.getElementById(`acc-${eleNumber}`);
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setActiveKey(event);
    }
  };

  const convertEuro = (numb: number) => {
    let value = null;
    switch (env.Country) {
      case 'NL':
        value = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(numb || 0));
        break;
      case 'ABW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AWG',
        }).format(Number(numb || 0));
        break;
      case 'CUW':
        value = `Cg ${new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(Number(numb))}`;
        break;
      default:
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(numb || 0));
        break;
    }
    return value || 0;
  };

  const phoneDataLayerHandler = () => {
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: {
        stap_aanvraag: 'Telefoonnummer',
        telefoon_klik: true,
      },
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
  };

  const dataLayerHandler = () => {
    const existingLoanInfo = JSON.parse(localStorage.loanInfo || '{}');
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: {
        event: 'Krediet aanvraag stap',
        stap_aanvraag: 'Aanvraag verzonden',
        // eslint-disable-next-line camelcase
        type_aanvraag: existingLoanInfo.dataLayer.type_aanvraag,
        leenbedrag: existingLoanInfo.dataLayer.leenbedrag,
        looptijd: existingLoanInfo.dataLayer.looptijd,
        mailadres: existingLoanInfo.dataLayer.mailadres,
        kvk: existingLoanInfo.dataLayer.kvk,
        // eslint-disable-next-line camelcase
        lead_id: existingLoanInfo.dataLayer.lead_id,
        niewsbrief_inschrijving: checked ? true : false,
      },
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
};

  const handleFinalMove = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      dataLayerHandler();
      return;
    }
    const payload = {
      Opt_In_newsletter_subscription__c: checked,
      Description: description,
      Credit_Application_Completed__c: true,
      Status: 'New',
    };
    store.dispatch(
      setLeadData({
        ...store.getState().lead,
        ...payload,
        ApplicationData__c: JSON.stringify({
          ...store.getState().lead,
          ...payload,
        }),
      }),
    );
    finalCall(payload);
  };

  const finalCall = (payload) => {
    const toastId = toast.loading('loading');
    const leadObj = {...store.getState().lead};
    const whitelist = [
      'Partner_ApplicationData__c',
      'Lead_Scoring__c',
      'Company',
      'Company_Postal_Code_Visiting__c',
      'Company_Street_Number_visiting__c',
      'Company_City_visiting__c',
      'Company_Street_Nr_Addition_visiting__c',
      'Company_Country_visiting__c',
      'Company_street_visiting__c',
      'COC_Number__c',
      'CoC_Registration_date__c',
      'FirstName',
      'LastName',
      'Email',
      'Phone',
      'Gender__c',
      'Lead_Scoring__c',
      'Agree_to_Privacy_Policy__c',
      'RequestedCreditAmount__c',
      'Duration__c',
      'Company_Neighbourhood_District__c',
      'Status',
      'Initials__c',
      'Google_CID__c',
      'AccountId__c',
      'Proposition__c',
      'Documents_Attached__c',
      'Birthdate__c',
      'Postal_Code_Visiting__c',
      'Street_Number_visiting__c',
      'Street_Number_Addition_visiting__c',
      'Street_visiting__c',
      'City_visiting__c',
      'Country_visiting__c',
      'Married__c',
      'Spouse_application_data__c',
      'Legal_Form__c',
    'CRIB_number__c',
    'Branche__c',
    'Neighbourhood_District__c',
    'Country_of_Birth__c',
    ];

    const whitelistedObject = Object.assign(
      {},
      ...whitelist.map((key) => ({[key]: leadObj[key]})),
    );
    Object.keys(whitelistedObject).forEach((key) => {
      if (
        whitelistedObject[key] === null ||
        whitelistedObject[key] === '' ||
        whitelistedObject[key] === undefined
      ) {
        delete whitelistedObject[key];
      }
    });
    const applicationData = {
      ...whitelistedObject,
    };
    delete applicationData.Documents_Attached__c;
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
      ...whitelistedObject,
      Opt_In_newsletter_subscription__c: checked,
      Duration__c: Number(whitelistedObject.Duration__c) * 12,
      ApplicationData__c: JSON.stringify(applicationData),
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: {
    //       ...payload,
    //       ...whitelistedObject,
    //       Duration__c: Number(whitelistedObject.Duration__c) * 12,
    //       ApplicationData__c: JSON.stringify(applicationData),
    //     },
    //   })
      .then(async () => {
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        dataLayerHandler();
        await store.dispatch(setFinalStep(true));
        await store.dispatch(resetState());
        setActiveKey(0);
        setCompleted(0);
        switch (env.Country) {
          case 'NL':
            window.open('https://qredits.nl/bedankt-voor-je-krediet-aanvraag/');
            break;
          case 'ABW':
            window.open('https://aruba-nl.qredits.com/bedankt-voor-je-kredietaanvraag/');
            break;
          case 'CUW':
            window.open('https://curacao-nl.qredits.com/bedankt-voor-je-kredietaanvraag/');
            break;
          case 'SXM':
            window.open('https://stmaarten-nl.qredits.com/bedankt-voor-je-kredietaanvraag/');
            break;
          case 'BES':
            window.open('https://bonaire-nl.qredits.com/bedankt-voor-je-kredietaanvraag/');
            break;
          default:
            window.open('https://qredits.nl/bedankt-voor-je-krediet-aanvraag/');
            break;
        }
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  const handleActive = (active: number) => {
    setActiveKey(active);
  };

  const handleClickChat = () => {
    const secondBtn = document.querySelector('.helpButtonEnabled');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    secondBtn.click();
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: {
        stap_aanvraag: 'Chatfunctie',
        chat_klik: true,
      },
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
  };

  return (
    <div className="container h-100">
      {state.appLoading === false ? (
        <div>
          <div>
            {!isMobile ? (
              <Row>
                <Col
                  xs={12}
                  md={6}
                  style={{
                    paddingLeft: isTablet ? '10px' : '0px',
                  }}
                >
                  <div className="application_title">
                    {/* {env.Country !== 'NL' && env.Country !== 'ABW' && (
                      <p>
                        {translate('welcome_msg')} {findCountryByEnv()}{' '}
                        {translate('application_module')}
                      </p>
                    )} */}
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={7}
                  style={{
                    paddingLeft: isTablet ? '10px' : '0px',
                    height: '100vh',
                    overflowY: 'auto',
                  }}
                >
                  {activeKey < 8 ? (
                    <div className="row m-0">
                      <div
                        className={`col ${!isTablet && 'desktop-vstack'} pr-0
                d-none d-md-none d-sm-none d-lg-block`}
                        style={{
                          paddingBottom: isTablet ? '12px' : '100px',
                          paddingLeft: isTablet ? '10px' : '0px',
                          paddingRight: isTablet ? '10px' : '0px',
                          paddingTop: isTablet ? '50px' : '80px',
                        }}
                      >
                        <Stack direction={isTablet ? 'horizontal' : 'vertical'}>
                          <div
                            className={`nav__dot ${
                              completed > 0 ? 'bg-success' : 'bg-light'
                            } ${activeKey === 0 ? 'nav_active' : ''}`}
                            onClick={() => handleActive(0)}
                          ></div>
                          <div
                            className={`nav__dot ${
                              completed > 1 ? 'bg-success' : 'bg-light'
                            } ${activeKey === 1 ? 'nav_active' : ''}`}
                            onClick={() =>
                              handleActive(completed >= 1 ? 1 : activeKey)
                            }
                          ></div>
                          <div
                            className={`nav__dot ${
                              completed > 2 ? 'bg-success' : 'bg-light'
                            } ${activeKey === 2 ? 'nav_active' : ''}`}
                            onClick={() =>
                              handleActive(completed >= 2 ? 2 : activeKey)
                            }
                          ></div>
                          <div
                            className={`nav__dot ${
                              completed > 3 ? 'bg-success' : 'bg-light'
                            } ${activeKey === 3 ? 'nav_active' : ''}`}
                            onClick={() =>
                              handleActive(completed >= 3 ? 3 : activeKey)
                            }
                          ></div>
                          <div
                            className={`nav__dot ${
                              completed > 4 ? 'bg-success' : 'bg-light'
                            } ${activeKey === 4 ? 'nav_active' : ''}`}
                            onClick={() =>
                              handleActive(completed >= 4 ? 4 : activeKey)
                            }
                          ></div>
                          {leadInfo.Married__c && (
                            <div
                              className={`nav__dot ${
                                completed > 5 ? 'bg-success' : 'bg-light'
                              } ${activeKey === 5 ? 'nav_active' : ''}`}
                              onClick={() =>
                                handleActive(completed >= 5 ? 5 : activeKey)
                              }
                            ></div>
                          )}
                          <div
                            className={`nav__dot ${
                              completed > 6 ? 'bg-success' : 'bg-light'
                            } ${activeKey === 6 ? 'nav_active' : ''}`}
                            onClick={() =>
                              handleActive(completed >= 6 ? 6 : activeKey)
                            }
                          ></div>
                        </Stack>
                      </div>
                      <div
                        id="accordionExample"
                        className="accordion col-11"
                        style={{
                          paddingBottom: '120px',
                          marginTop: isTablet ? '12px' : '50px',
                          paddingLeft: isTablet ? '10px' : '0px',
                          overflowY: 'auto',
                          height: '100%',
                        }}
                      >
                        <div className="desktop-credit">
                          {env.Country !== 'NL' && (
                            <p>
                              {translate('welcome_msg')} {findCountryByEnv()}{' '}
                              {translate('application_module')}
                            </p>
                          )}
                          {env.Country === 'NL' && (
                            <div>
                              {!aanvraag ?
                                `${translate('are_you_financial_advisor')}
${translate('go_to')}` :
                                translate('are_you_not_financial_advisor')}
                              &nbsp;
                              <a
                                href="https://www.qredits.nl/intermediairs"
                                className="text-warning cursor-pointer"
                                onClick={() => setAanvraag(false)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {aanvraag ?
                                  translate('normal_request') :
                                  translate('intermediate_request')}
                              </a>
                            </div>
                          )}
                        </div>
                        {/* {activeKey === 1 && <p className="mt-1 desktop-credit">
                    Ben je een intermediair? &nbsp;
                    <span className="text-warning cursor-pointer">
                      Klik dan hier.
                    </span>
                  </p>} */}
                        <Card
                          id="acc-1"
                          className={`mb-3 p-3 p-md-4 p-lg-4
                  ${completed >= 0 && 'accordion-item'}`}
                        >
                          <CustomToggle
                            activeKey={activeKey}
                            eventKey={0}
                            completed={completed}
                            decoratedOnClick={() => selectAccord(0)}
                          >
                            <div className="accordion_title">
                              {!aanvraag ?
                                translate('what_you_need') :
                                translate('intermediary_application')}
                            </div>
                          </CustomToggle>
                          <Collapse
                            in={activeKey === 0}
                            data-bs-parent="#accordionExample"
                          >
                            <div>
                              {!aanvraag ? (
                                <DesktopCreditForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  getData={handleGetData}
                                />
                              ) : (
                                <DesktopContactForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  aanvraag={aanvraag}
                                />
                              )}
                            </div>
                          </Collapse>
                        </Card>
                        <Card
                          id="acc-2"
                          className={`mb-3 p-3 p-md-4 p-lg-4
                  ${completed >= 1 && 'accordion-item'}`}
                        >
                          <CustomToggle
                            activeKey={activeKey}
                            eventKey={1}
                            completed={completed}
                            decoratedOnClick={() => selectAccord(1)}
                          >
                            <div className="accordion_title">
                              {!aanvraag ?
                                translate('contact_details') :
                                translate('your_company_data')}
                            </div>
                          </CustomToggle>
                          <Collapse in={activeKey === 1}>
                            <div>
                              {!aanvraag ? (
                                <DesktopContactForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                />
                              ) : (
                                <DesktopCompanyForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  aanvraag={aanvraag}
                                />
                              )}
                            </div>
                          </Collapse>
                        </Card>
                        <Card
                          id="acc-3"
                          className={`mb-3 p-3 p-md-4 p-lg-4
                  ${completed >= 2 && 'accordion-item'}`}
                        >
                          <CustomToggle
                            activeKey={activeKey}
                            eventKey={2}
                            completed={completed}
                            decoratedOnClick={() => selectAccord(2)}
                          >
                            <div className="accordion_title">
                              {!aanvraag ?
                                translate('company_information_title') :
                                translate('personal_information')}
                            </div>
                          </CustomToggle>
                          <Collapse in={activeKey === 2}>
                            <div>
                              {!aanvraag ? (
                                <DesktopCompanyForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                />
                              ) : (
                                <DesktopPersonForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  aanvraag={aanvraag}
                                />
                              )}
                            </div>
                          </Collapse>
                        </Card>
                        <Card
                          id="acc-4"
                          className={`mb-3 p-3 p-md-4 p-lg-4
                  ${completed >= 3 && 'accordion-item'}`}
                        >
                          <CustomToggle
                            activeKey={activeKey}
                            eventKey={3}
                            completed={completed}
                            decoratedOnClick={() => selectAccord(3)}
                          >
                            <div className="accordion_title">
                              {!aanvraag ?
                                translate('personal_information') :
                                translate('associates_title')}
                            </div>
                          </CustomToggle>
                          <Collapse in={activeKey === 3}>
                            <div>
                              {!aanvraag ? (
                                <DesktopPersonForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                />
                              ) : (
                                <BusinessPartnerForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  aanvraag={aanvraag}
                                />
                              )}
                            </div>
                          </Collapse>
                        </Card>
                        {leadInfo.Married__c && (
                          <Card
                            id="acc-5"
                            className={`mb-3 p-3 p-md-4 p-lg-4
                        ${completed >= 4 && 'accordion-item'}`}
                          >
                            <CustomToggle
                              activeKey={activeKey}
                              eventKey={4}
                              completed={completed}
                              isValid={!leadInfo.Married__c}
                              decoratedOnClick={() => selectAccord(4)}
                            >
                              <div className="accordion_title">
                                {!aanvraag ?
                                  translate('spouse') :
                                  translate('associates_title')}
                              </div>
                            </CustomToggle>
                            <Collapse in={activeKey === 4}>
                              <div>
                                {!aanvraag ? (
                                  <DesktopSpouseForm
                                    handleDone={(e: handleDoneProps) =>
                                      handleDesktopForm(e)
                                    }
                                  />
                                ) : (
                                  <BusinessPartnerForm
                                    handleDone={(e: handleDoneProps) =>
                                      handleDesktopForm(e)
                                    }
                                    aanvraag={aanvraag}
                                  />
                                )}
                              </div>
                            </Collapse>
                          </Card>
                        )}
                        <Card
                          id="acc-6"
                          className={`mb-3 p-3 p-md-4 p-lg-4
                  ${
                    completed >= 5 &&
                    leadInfo.Company_type !== 'eenmanszaak' &&
                    'accordion-item'
                  }`}
                        >
                          <CustomToggle
                            activeKey={activeKey}
                            eventKey={5}
                            completed={completed}
                            isValid={leadInfo.Company_type === 'eenmanszaak'}
                            decoratedOnClick={() => selectAccord(5)}
                          >
                            <div className="accordion_title">
                              {!aanvraag ?
                                translate('associates_title') :
                                translate('company_documents_title')}
                            </div>
                          </CustomToggle>
                          <Collapse in={activeKey === 5}>
                            <div>
                              {!aanvraag ? (
                                <BusinessPartnerForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                />
                              ) : (
                                <DesktopDocumentsForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  aanvraag={aanvraag}
                                  listFiles={listFiles} setListFiles={setListFiles}
                                />
                              )}
                            </div>
                          </Collapse>
                        </Card>
                        {!aanvraag && (
                          <Card
                            id="acc-7"
                            className={`sixth mb-3 p-3 p-md-4 p-lg-4
                  ${completed >= 6 && 'accordion-item'}`}
                          >
                            <CustomToggle
                              activeKey={activeKey}
                              eventKey={6}
                              completed={completed}
                              decoratedOnClick={() => selectAccord(6)}
                            >
                              <div className="accordion_title">
                                {translate('company_documents_title')}
                              </div>
                            </CustomToggle>
                            <Collapse in={activeKey === 6}>
                              <div>
                                <DesktopDocumentsForm
                                  handleDone={(e: handleDoneProps) =>
                                    handleDesktopForm(e)
                                  }
                                  listFiles={listFiles} setListFiles={setListFiles}
                                />
                              </div>
                            </Collapse>
                          </Card>
                        )}
                        <Card
                            id="acc-10"
                            className={`seventh mb-3 p-3 p-md-4 p-lg-4
                  ${completed >= 7 && 'accordion-item'}`}
                        >
                            <CustomToggle
                              activeKey={activeKey}
                              eventKey={7}
                              completed={completed}
                              decoratedOnClick={() => selectAccord(7)}
                            >
                              <div className="accordion_title">
                                {translate('send')}
                              </div>
                            </CustomToggle>
                            <Collapse in={activeKey === 7}>
                            <div className='mt-4 desktop_form'>
                            <p className="mt-1 mobile_details">
        {translate('questions_comments')}
      </p>
                            <Form noValidate onSubmit={handleFinalMove}>
                              <Form.Group controlId="validationCustom02" className="mobile-commentbox">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label={
                                    description.length > 250 ?
                                      '' :
                                      translate('comments')
                                  }
                                  className="mb-3"
                                >
                                  <Form.Control
                                    as="textarea"
                                    placeholder={''}
                                    maxLength={3000}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => setDescription(e.target.value)}
                                    style={{height: '100px'}}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              {env.Country === 'NL' && <Form.Group controlId="validationCustom05" className='normal-checked'>
                                <Form.Check
                                  type="checkbox"
                                  className='desktop-checkbox'
                                  id="default-checkbox"
                                  checked={checked}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => setChecked(e.target.checked)}
                                  label={translate('sign_up_newsletter')}
                                />
                              </Form.Group>}
                              <div>
                          <button
                            type="submit"
                            className="btn
                        btn-success px-5 mobile_button mt-3"
                          >
                            {translate('confirm_send')}
                          </button>
                        </div>
                            </Form>
                          </div>
                            </Collapse>
                          </Card>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="accordionExample"
                      className="accordion col-11"
                      style={{
                        paddingBottom: '120px',
                        marginTop: isTablet ? '12px' : '50px',
                        paddingLeft: isTablet ? '10px' : '0px',
                        overflowY: 'auto',
                        height: '100%',
                        overflowX: 'hidden',
                      }}
                    >
                      <div className="py-5">
                        <p className="mobile_header">
                          {state.lead.FirstName && `${state.lead.FirstName},`}{' '}
                          {translate('thank_you_application')}
                        </p>
                        <div className="desktop_details mb-3">
                          <span>{translate('confirmation_email_msg')}</span>
                        </div>
                        {/* <div>
                      <img src={Confetti} height={500} className="m-3"/>
                    </div> */}
                        <div>
                          <button
                            type="submit"
                            className="btn btn-light-blue
                        w-100 mobile_button mt-3"
                            onClick={() => handleDone(0)}
                          >
                            {translate('go_back_to_homepage')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={12} md={5}>
                  <div className="py-5">
                    <p className="text-start desktop_header mb-4">
                      {translate('example_calculation')}
                    </p>
                    <div className="desktop_details mb-4">
                      <div className="mb-4">
                        <Row className="align-items-center mb-2">
                          <Col xs={7} md={7} className="col-7">
                            <span>{translate('loan_amt')}</span>
                          </Col>
                          <Col xs={5} md={5} className="col-5 text-end figures_details">
                            <span>{convertEuro(loan || 0)}</span>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                          <Col xs={7} md={7} className="col-7">
                            <span>{translate('duration')}</span>
                          </Col>
                          <Col xs={5} md={5} className="col-5 text-end figures_details">
                            <span>
                              {year || 0} {translate('year')}
                            </span>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="mt-4">
                        <Row className="align-items-center mb-4">
                          <Col xs={7} md={7} className="col-7">
                            <span>{translate('payable_per_month')}*</span>
                          </Col>
                          <Col xs={5} md={5} className="col-5 text-end">
                            <span>{convertEuro(monthlyPayment)}</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="text-start">
                    <div className={`desktop_sub_details w-60 text-start ${env.Country !== 'NL' ? 'mb-5 pb-5' : 'mb-2'}`}>
                        *{translate('based_on_interest')} {interestRate}%.
                      </div>
                      {env.Country === 'NL' && <div className="desktop_sub_details mb-5 pb-5 w-60 text-start">
                        {translate('value_after_request_text')}
                      </div>}
                      <p className="text-start desktop_sub_header mb-3">
                        {translate('any_question_msg')}
                      </p>
                      <div className="desktop_sub_details mb-2 w-60 text-start">
                        {translate('contact_customer_service_msg')}
                        {/* <span className="text-warning">
                      support pagina.
                    </span> */}
                      </div>
                      <p className="mb-2 mt-1">
                      </p>
                        <div className="d-flex m-0 desktop_sub_details align-items-center">
                      {env.Country === 'NL' && (
                          <div className="mobile_footer_tel mb-1 pb-1 pr-4">
                        <img
                          className="mobile-brand chat-icon"
                          src={ChatIcon}
                          alt=""
                          height={30}
                          width={30}
                        />
                          <a
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => handleClickChat()}
                            style={{textDecoration: 'none'}}
                            className="mobile_footer_text text-primary"
                          >
                            Start de chat
                          </a>
                      </div>
                      )}
                          <div className="mobile_footer_tel mb-1 pb-1">
                        <img
                          className="mobile-brand"
                          src={PhoneIcon}
                          alt=""
                          height={32}
                          width={32}
                        />
                          <a
                            href={`tel:${findCallNumByEnv()}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => phoneDataLayerHandler()}
                            style={{textDecoration: 'none'}}
                            className="mobile_footer_text text-primary"
                          >
                            {findCallNumByEnv()}
                          </a>
                      </div>
                        </div>

<div className="my-4">
            <div className='flex m-0 desktop_footer_checktext font-weight-bold mb-3'>
            <img
                className="desktop-brand"
                src={CheckIcon}
                alt=""
                height={21}
                width={23}
            />
                          <div style={{marginLeft: '8px'}}>
              {translate('Usp_1')}
                          </div>
            </div>
            <div className='flex desktop_footer_checktext font-weight-bold mb-3'>
            <img
                className="desktop-brand"
                src={CheckIcon}
                alt=""
                height={21}
                width={23}
            />
              <div style={{marginLeft: '8px'}}>
              {translate('Usp_2')}
                          </div>
            </div>
            <div className='flex desktop_footer_checktext font-weight-bold mb-3'>
            <img
                className="desktop-brand"
                src={CheckIcon}
                alt=""
                height={21}
                width={23}
            />
              <div style={{marginLeft: '8px'}}>
              {translate('Usp_3')}
                          </div>
            </div>
            </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="container mobile_nav pt-3">
                <div className="d-flex justify-content-center pb-3 mx-auto">
                  <div className="d-flex">
                    <div
                      className={`nav__dot ${
                        completed > 0 ? 'bg-success' : 'bg-light'
                      } ${activeKey === 0 ? 'nav_active' : ''}`}
                      onClick={() => handleActive(0)}
                    ></div>
                    <div
                      className={`nav__dot ${
                        completed > 1 ? 'bg-success' : 'bg-light'
                      } ${activeKey === 1 ? 'nav_active' : ''}`}
                      onClick={() =>
                        handleActive(completed >= 1 ? 1 : activeKey)
                      }
                    ></div>
                    <div
                      className={`nav__dot ${
                        completed > 2 ? 'bg-success' : 'bg-light'
                      } ${activeKey === 2 ? 'nav_active' : ''}`}
                      onClick={() =>
                        handleActive(completed >= 2 ? 2 : activeKey)
                      }
                    ></div>
                    <div
                      className={`nav__dot ${
                        completed > 3 ? 'bg-success' : 'bg-light'
                      } ${activeKey === 3 ? 'nav_active' : ''}`}
                      onClick={() =>
                        handleActive(completed >= 3 ? 3 : activeKey)
                      }
                    ></div>
                    {leadInfo.Married__c && (
                      <div
                        className={`nav__dot ${
                          completed > 4 ? 'bg-success' : 'bg-light'
                        } ${activeKey === 4 ? 'nav_active' : ''}`}
                        onClick={() =>
                          handleActive(completed >= 4 ? 4 : activeKey)
                        }
                      ></div>
                    )}
                    <div
                      className={`nav__dot ${
                        completed > 5 ? 'bg-success' : 'bg-light'
                      } ${activeKey === 5 ? 'nav_active' : ''}`}
                      onClick={() =>
                        handleActive(completed >= 5 ? 5 : activeKey)
                      }
                    ></div>
                    <div
                      className={`nav__dot ${
                        completed > 6 ? 'bg-success' : 'bg-light'
                      } ${activeKey === 6 ? 'nav_active' : ''}`}
                      onClick={() =>
                        handleActive(completed >= 6 ? 6 : activeKey)
                      }
                    ></div>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: '10px',
                    paddingTop: '15px',
                    overflowY: 'auto',
                    height: '100%',
                    overflowX: 'hidden',
                  }}
                >
                  {activeKey === 0 && (
                    <MobileCreditForm
                      handleDone={(e: number) => handleDone(e)}
                      getData={handleGetData}
                    />
                  )}
                  {activeKey === 1 && (
                    <MobileContactForm
                      handleDone={(e: number) => handleDone(e)}
                    />
                  )}
                  {activeKey === 2 && (
                    <MobileCompanyForm
                      handleDone={(e: number) => handleDone(e)}
                    />
                  )}
                  {activeKey === 3 && (
                    <MobilePersonForm
                      handleDone={(e: number) =>
                        handleDone(
                          leadInfo.Company_type === 'eenmanszaak' ? 5 : e,
                        )
                      }
                    />
                  )}
                  {activeKey === 4 && (
                    <MobileSpouseForm
                      handleDone={(e: number) => handleDone(e)}
                    />
                  )}
                  {activeKey === 5 && (
                    <MobileBusinessForm
                      handleDone={(e: number) => handleDone(e)}
                    />
                  )}
                  {activeKey === 6 && (
                    <MobileDocumentsForm listFiles={listFiles} setListFiles={setListFiles}
                      handleDone={(e: number) => handleDone(e)}
                    />
                  )}
                    {activeKey === 7 && (
                      <div>
                    <div
                      style={{
                        marginTop: isTablet ? '12px' : '50px',
                        paddingLeft: isTablet ? '10px' : '0px',
                        overflowY: 'auto',
                        height: 'auto',
                        overflowX: 'hidden',
                      }}
                          className='mobile_form_comments'
                    >
                        <div className="mobile_header">{translate('send')}</div>
      <p className="mt-1 mobile_details">
        {translate('questions_comments')}
      </p>
                      <Form noValidate onSubmit={handleFinalMove}>
                        <Form.Group controlId="validationCustom02" className="mobile-commentbox">
                            <FloatingLabel
                            controlId="floatingInput"
                            label={
                              description.length > 250 ?
                                '' :
                                translate('add_comments')
                            }
                            >
                            <Form.Control
                              as="textarea"
                              placeholder={''}
                              maxLength={3000}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => setDescription(e.target.value)}
                              style={{height: '150px'}}
                            />
                          </FloatingLabel>
                          </Form.Group>
                        {env.Country === 'NL' && <Form.Group controlId="validationCustom05">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            checked={checked}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => setChecked(e.target.checked)}
                            label={translate('sign_up_newsletter')}
                          />
                        </Form.Group>}
                        <div className="mt-1 pb-4">
                          <button
                            type="submit"
                            className="btn
                        btn-success px-5 mobile_button mt-3"
                          >
                            {translate('confirm_send')}
                          </button>
                        </div>
                        </Form>
                        <div className="previous-button pb-4" onClick={() => handleDone(6)}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.52515 2.48282L3.92985 6.9926L8.525 11.5024L6.50104
 13.4889L0.893766 7.98578C0.623402 7.72045 0.474475
  7.36771 0.474475 6.9926C0.474475 6.61748 0.623402
   6.26474 0.893615 5.99941L6.50104 0.496299L8.52515
    2.48282Z" fill="#CECCD5"
          />
</svg>
{translate('previous_step')}
        </div>
                    </div>
                    </div>
                  )}
                  {activeKey === 8 && (
                    <div
                      id="accordionExample"
                      className="accordion col-11"
                      style={{
                        paddingBottom: '10px',
                        marginTop: isTablet ? '12px' : '50px',
                        paddingLeft: isTablet ? '10px' : '0px',
                        overflowY: 'auto',
                        height: '100%',
                        overflowX: 'hidden',
                      }}
                    >
                      <div className="py-5">
                        <p className="mobile_header">
                          {state.lead.FirstName},{' '}
                          {translate('thank_you_application')}
                        </p>
                        <div className="desktop_details mb-3">
                          <span>{translate('confirmation_email_msg')}</span>
                        </div>
                        {/* <div>
                      <img src={Confetti} height={500} className="m-3"/>
                    </div> */}
                        <div>
                          <button
                            type="submit"
                            className="btn btn-light-blue
                        w-100 mobile_button mt-3"
                            onClick={() => handleDone(0)}
                          >
                            {translate('go_back_home')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingBottom: isTablet ? '12px' : '10px',
            paddingLeft: isTablet ? '10px' : '0px',
            paddingRight: isTablet ? '10px' : '0px',
              paddingTop: isTablet ? '50px' : '80px',
            height: '100vh',
          }}
        >
          {translate('loading')}
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
        </div>
      )}
    </div>
  );
};

export default Home;
