/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {useSelector} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {stateType, MetaDataProps} from '@/types/state';
import InputField from '@/components/common/InputField';
import store, {setLeadData, setSelectedProduct} from '@/store';
import useLocales from '@/hooks/useLocales';
import InputGroup from 'react-bootstrap/InputGroup';
// import apiHttp from '@/apis/axiosApi';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';

type Props = {
  handleDone: ({loan, year, key}) => void;
  getData: ({loan, year, interestRate}) => void;
};
const durationMap: Record<string, number> = {
  NL: 5,
  SXM: 6,
  BES: 5,
  default: 6, // Default value if no match is found
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CreditForm = ({handleDone, getData}: Props) => {
  const inputLoanRef = React.useRef(null);
  const inputYearRef = React.useRef(null);
  const state = useSelector((state: stateType) => state);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [year, setYear] = React.useState(
    state.lead.Duration__c ?
      Number(state.lead.Duration__c) : durationMap[env.Country] ||
      durationMap.default);
  const [newYear, setNewYear] = React.useState<number>(50);
  const [loan, setLoan] = React.useState(
    state.lead.RequestedCreditAmount__c ?
    Number(state.lead.RequestedCreditAmount__c ): env.Country !== 'NL' ?
  25000 : 20000);
  const [maxYearValue, setMaxYearValue] = React.useState(0);
  // const [minYearValue, setMinYearValue] = React.useState(0);
  let [valueLoan, setValue] = React.useState<number>(50);
  const progressYear = (newYear / 100) * 100 + '%';
  const active = '#5EC369';
  const inactive = '#002172';
  const [interestRate, setInterestRate] = React.useState<number>(8.75);
  const [product, setProduct] = React.useState<string>(
    state.lead.Proposition__c ?
   state.lead.Proposition__c : '');
  const [coCNumber, setCoCNumber] = React.useState<string>(
    state.lead.COC_Number__c ?
    state.lead.COC_Number__c : '');
  const [products, setProducts] = React.useState<MetaDataProps[]>([]);
  const styleLoanInput = {
    background: `linear-gradient(90deg, ${active} 0% ${valueLoan}%,
        ${inactive} ${valueLoan}% 100%)`,
  };
  const styleYearInput = {
    background: `linear-gradient(90deg, ${active} 0% ${progressYear},
       ${inactive} ${progressYear} 100%)`,
  };
  const {translate} = useLocales();

  const firstQuadraticValue = env.Country !== 'NL' ? 44555.6 : 46777.8;
  const secondQuadraticValue = env.Country !== 'NL' ? 50500 : 57166.7;
  const thirdQuadraticFactor = env.Country !== 'NL' ? 500 :
    390;

  const logslider = (position) => {
    // position will be between 0 and 100
    const minp = 0;
    const maxp = 100;

    // The result should be between 1 and 20
    const minv = Math.log(1);
    const maxv = Math.log(20);

    // calculate adjustment factor
    const scale = (maxv - minv) / (maxp - minp);

    return Math.round(Math.exp(minv + scale * (position - minp)));
  };

  const logposition = (value) => {
    // value will be between 1 and 20
    const minv = Math.log(1);
    const maxv = Math.log(20);

    // The result should be between 0 and 100
    const minp = 0;
    const maxp = 100;

    // calculate adjustment factor
    const scale = (maxv+0.3 - minv) / (maxp - minp);

    return Math.round((Math.log(value) - minv) / scale + minp);
  };

  const handleNewYearChange = (event) => {
    const inputValue = parseInt(event.target.value);
    // Calculate the max duration based on the logarithmic scale
    setYear(logslider(inputValue));
    setNewYear(inputValue);
    const progress = (inputValue / 100) * 100 + '%';
    const payload = {
      Duration__c: logslider(inputValue),
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
       ${progress}%,
       ${inactive} ${progress}% 100%)`;
    if (inputYearRef.current?.style)
      inputYearRef.current.style.background = newBackgroundStyle;
  };

  const transform = (value) => {
    let newValue = 0;
    if (value < 50) {
      newValue = thirdQuadraticFactor * value + 500;
    } else {
      value = value / 33.33333333333333333;
      newValue = Math.floor(
        firstQuadraticValue * Math.pow(value, 2) - secondQuadraticValue *
        value + 500,
      );
    }
    return Math.floor(newValue / 100) * 100;
  };

  const solveQuadratic = (y) => {
    const b = -secondQuadraticValue;
    const c = 500 - y;
    const discriminant = b ** 2 - 4 * firstQuadraticValue * c;

    if (discriminant < 0) {
      // The quadratic equation has no real solutions for the given value of y
      return NaN;
    }

    const x1 = (-b + Math.sqrt(discriminant)) / (2 * firstQuadraticValue);
    const x2 = (-b - Math.sqrt(discriminant)) / (2 * firstQuadraticValue);

    // Return the bigger of the two solutions
    return Math.max(x1, x2);
  };

  const transformBack = (value) => {
    let newValue = 0;
    const theMedian = env.Country !== 'NL' ? 25000 : 20000;
    if (value <= theMedian) {
      newValue = (value - 500) / thirdQuadraticFactor;
    } else {
      newValue = solveQuadratic(value);
      newValue = newValue * (100 / 3);
    }
    return Math.round(newValue);
  };

  const handleLoanChange = () => () => {
    const inputs = JSON.parse(
      (document.getElementById('sliderId') as HTMLInputElement).value,
    );
    valueLoan = transform(inputs);
    setLoan(valueLoan);
    setValue(inputs);
    const progress = (inputs / 100) * 100 + '%';
    const payload = {
      RequestedCreditAmount__c: valueLoan,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
      ${progress}%,
      ${inactive} ${progress}% 100%)`;
   inputLoanRef.current.style.background = newBackgroundStyle;
  };

  const convertEuro = (numb: number, type?: string) => {
    let value = null;
    switch (env.Country) {
      case 'NL':
        value = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(numb));
      break;
      case 'ABW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AWG',
        }).format(Number(numb));
      break;
      case 'CUW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'ANG',
        }).format(Number(numb));
      break;
      default:
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(numb));
        break;
    }
    const firstNumberIndex = value.search(/[0-9]/);
    if (type === 'currency')
      return value.substring(0, firstNumberIndex);
    else if (type === 'text')
      return value.match(/[0-9.,]+/g)?.join('') || '';
    else return value;
  };

  const handleLoanText = (event) => {
    let valueText = 0;
    const eventReplaceDot = env.Country === 'NL' ? event
      .replace(/\./g, '')
      .replace(',', '.')
      // eslint-disable-next-line
      .replace(/[^0-9\.-]+/g, "") : event.replace(/[^0-9\.-]+/g, "");
    valueText =
      parseFloat(eventReplaceDot) >= 250000 ?
        250000 :
        parseFloat(eventReplaceDot);
    valueLoan = transformBack(valueText);
    setLoan(valueText);
    setValue(valueLoan);
    const payload = {
      RequestedCreditAmount__c: valueText,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
  };
  const tagManagerArgs = {
    gtmId: env.GtmId,
    dataLayer: {
      'event': 'Krediet aanvraag stap',
      'stap_aanvraag': 'Kredietinformatie',
      'type_aanvraag': product,
      'leenbedrag': loan,
      'looptijd': year,
    },
  };
  const addDataLayer = () => {
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(tagManagerArgs.dataLayer));
  };

  const handleYearChange = (event) => {
    const value = Number(event.target.value);
    const progress = (logposition(value) / 100) * 100 + '%';
    setYear(value);
    setNewYear(logposition(value));
    const payload = {
      Duration__c: value,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
       ${progress}%,
       ${inactive} ${progress}% 100%)`;
    if (inputYearRef.current?.style)
      inputYearRef.current.style.background = newBackgroundStyle;
  };

  React.useEffect(() => {
    getData({year, loan, interestRate});
    if (year > 0 && loan > 0 && product) setValidated(true);
    else setValidated(false);
  }, [year, loan, interestRate, product]);

  React.useEffect(() => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(Array.from(searchParams.entries()));
    if (!product) {
      const prod = state.metaData.filter((el) => !!el.InterestPercentage__c);
      // Find the index of the element with the specific id
      const indexToMove = prod.findIndex(
        (obj) => obj.Label === 'Zakelijk Krediet',
      );

      // If the element is found, move it to the beginning of the array
      if (indexToMove !== -1) {
        const elementToMove = prod.splice(indexToMove, 1)[0];
        prod.unshift(elementToMove);
      }
      setProducts(prod);
      const chosen = state.lead.Proposition__c || product;
      const prodfinal = prod.filter((el) => el.Label ===
      chosen);
      setProduct(prodfinal[0]?.Label || prod[0]?.Label);
      setInterestRate(prodfinal[0]?.InterestPercentage__c ||
        prod[0]?.InterestPercentage__c);
      store.dispatch(setSelectedProduct(prodfinal[0] || prod[0]));
      setLoan(state.lead.RequestedCreditAmount__c);
      setYear(state.lead.Duration__c);
    }
  }, [product, state]);

  React.useEffect(() => {
    const prod = products.filter((el) => el.Label === product);
    setProduct(prod[0]?.Label);
    setInterestRate(prod[0]?.InterestPercentage__c || 0);
    store.dispatch(setSelectedProduct(prod[0]));
  }, [product, products]);

  React.useEffect(() => {
    if (coCNumber) {
      const payload = {
        COC_Number__c: coCNumber,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    }
  }, [coCNumber]);

  React.useEffect(() => {
    handleLoanText(`${loan}`);
    handleYearChange({
      target: {
        value: year,
      },
    });
    setMaxYearValue(20);
    // setMinYearValue(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for (let i = 120; i >= 1; i -= 12) {
  //   options.push(<option value={i}>{i} maanden</option>);
  // }
  const updateLead = () => {
    const toastId = toast.loading(translate('loading'));
    const payload = {
      RequestedCreditAmount__c: loan,
      Duration__c: Number(year) * 12,
      Proposition__c: product,
    };
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: payload,
    //   })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  const handleSubmit = () => {
    addDataLayer();
    handleDone({
      loan,
      year,
      key: 1,
    });
    if (coCNumber) {
      const payload = {
        COC_Number__c: coCNumber,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    }
    if (store.getState().id) updateLead();
  };

  return (
    <Card.Body className="desktop_form">
      <p className="mt-4 mobile_details">
        {translate('fill_as_many_fields')}
      </p>
      <div className="mb-5 align-items-center">
        <Form.Group controlId="validationCustom01">
        <Row className="align-items-center mb-2">
            <Col xs={12} md={12} className="col-5 p-0
             row m-0 mt-3 align-items-begin"
            >
              <Form.Label>{translate('loan_amt')}</Form.Label>
            </Col>
          </Row>
          <InputGroup className="mb-3 desktop-loan-input">
            <InputGroup.Text>{convertEuro(loan, 'currency')}</InputGroup.Text>
        <InputField
                handleFocus={(e: string) => handleLoanText(e)}
                type="all"
                defaultValue={convertEuro(loan, 'text')}
              isNewInput
        />
      </InputGroup>
          <input
            ref={inputLoanRef}
            id="sliderId"
            className="inputR"
            name="sliderName"
            type="range"
            min={0}
            max={100}
            value={valueLoan}
            onChange={handleLoanChange()}
            style={styleLoanInput}
          />
        </Form.Group>
      </div>
      <div className="mb-5">
        <Form.Group controlId="validationCustom01">
        <Row className="align-items-end mb-1 mt-3">
            <Col xs={6} md={6} className="col-6 p-0 row m-0 align-items-end">
              <Form.Label>{translate('duration')}</Form.Label>
            </Col>
          </Row>
            <div className="mobile-loan-input mb-4">
          <Form.Select
            aria-label="Select year"
            value={year}
            onChange={(e) => handleYearChange(e)}
          >
            {Array(maxYearValue)
              .fill(0)
              .map((_, index) => (
                <option value={index + 1} key={index}>
                  {' '}
                  {index + 1} {translate('year')}
                </option>
              ))}
          </Form.Select>
          </div>
          <input
            type="range"
            id="sliderIds"
            className="inputR mt-6"
            name="sliderName"
            min="1"
            max="100"
            value={newYear}
            onChange={handleNewYearChange}
            step="1"
            style={styleYearInput}
          />
        </Form.Group>
      </div>
      <Row className="align-items-end mb-1 mt-3">
            <Col xs={6} md={6} className="col-6 p-0 row m-0 align-items-end">
              <Form.Label>{translate('credit_type')}</Form.Label>
            </Col>
          </Row>
      <div className="mobile-loan-input mb-3">
      <Form.Select
        aria-label="Select type"
        value={product}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setProduct(e.target.value)
        }
      >
        {products.map((product, index) => (
          <option value={product.Label} key={index}>
            {product.Label}
          </option>
        ))}
      </Form.Select>
          </div>
      <Row className="align-items-end m-0 mt-3">
        <Col xs={6} md={6} className="p-0 align-items-end">
          {/* <div className='text-orange m-0'>Heb je interesse in een
            specifiek product</div> */}
          {env.Country !== 'NL' && <div>
            <InputField
              isRequired={env.Country === 'CUW' || env.Country === 'SXM'}
              isValidated={setValidated}
              handleChange={(e: string) => setCoCNumber(e)}
              label={translate('COC Number')}
              type="number"
              labelClasses="level-input"
              defaultValue={coCNumber}
            />
          </div>}
        </Col>
        <Col xs={6} md={6}
          className="justify-content-end text-end p-0"
        >
          <div className="row m-0 justify-content-end">
              <button
                type="submit"
                className="btn btn-success
              px-4 mobile_button"
              disabled={!validated}
                onClick={handleSubmit}
              >
                {translate('start_application')}
              </button>
            </div>
        </Col>
      </Row>
    </Card.Body>
  );
};

export default CreditForm;
