import {stateType} from '@/types/state';

const initialState: stateType = {
  token: '',
  id: '',
  loan: '',
  year: '',
  appLoading: false,
  finalStep: false,
  lead: {
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    Gender__c: '',
    OwnerId: '',
    Status: '',
    Agree_to_Privacy_Policy__c: false,
    RequestedCreditAmount__c: 0,
    Duration__c: 0,
    Company: '',
    Company_Postal_Code_Visiting__c: '',
    Company_Street_Number_visiting__c: '',
    Street_Number_Addition_visiting__c: '',
    City_visiting__c: '',
    COC_Number__c: '',
    Birthdate__c: '',
    Postal_Code_Visiting__c: '',
    Street_Number_visiting__c: '',
    Street_visiting__c: '',
    CoC_Registration_date__c: '',
    Company_street_visiting__c: '',
    Country_visiting__c: '',
    Partner_ApplicationData__c: '',
    Proposition__c: '',
    Document_Financial_Plan__c: false,
    Document_Other_uploaded__c: false,
    Document_Investment_budget__c: false,
    PathOnClient: '',
    Married__c: false,
    Company_type: 'StartingEntrepreneur',
    Company_Street_Nr_Addition_visiting__c: '',
    Company_Country_visiting__c: '',
    CRIB_number__c: '',
    Neighbourhood_District_Account__c: '',
    Neighbourhood_District_Contact__c: '',
    Company_Neighbourhood_District__c: '',
    Spouse_application_data__c: '',
    Country_of_Birth__c: '',
    Lead_Scoring__c: '',
    Initials__c: '',
    Branche__c: '',
    AccountId__c: '',
    Id: '',
    LastModifiedDate: '',
   RT_developer_name__c: '',
   Name: '',
   CreatedById: '',
   PhotoUrl: '',
   IsDeleted: '',
   IsConverted: '',
   LastViewedDate: '',
   CreatedDate: '',
   LastReferencedDate: '',
   SystemModstamp: '',
   Lastname_w_o_prefix__c: '',
  LastModifiedById: '',
  Application_Submitted__c: '',
  Loan_Application_URL_c: '',
  Opt_In_newsletter_subscription__c: false,
  SessionID: '',
},
  docs: [],
  metaData: [],
  selectedProduct: {
    AdditionalDocuments__c: '',
    Description__c: '',
    Id: '',
    InterestPercentage__c: 0,
    Label: '',
  },
  products: [
    {
      name: 'Standaard',
      interest: 5.75,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 10,
      },
    },
    {
      name: 'Microkrediet',
      interest: 9.75,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 10,
      },
    },
    {
      name: 'MKB-krediet',
      interest: 8.75,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 10,
      },
    },
    {
      name: 'Sociaal krediet',
      interest: 7.75,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 10,
      },
    },
    {
      name: 'Lease',
      interest: 7.75,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 10,
      },
    },
    {
      name: 'Bedrijfshypotheek',
      interest: 7.75,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 20,
      },
    },
    {
      name: 'Flexibel krediet (1.2% per month)',
      interest: 14.4,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 1,
        max: 5,
      },
    },
    {
      name: 'MKB Duurzaamheidslening',
      interest: 2.5,
      credit: {
        min: 500,
        max: 250000,
      },
      time: {
        min: 5,
        max: 7,
      },
    },
  ],
};

export {initialState};
