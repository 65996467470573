/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {useSelector} from 'react-redux';
import type {stateType} from '@/types/state';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import apiHttp from '@/apis/axiosApi';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import store, {setID, setLeadData, getUser} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import TagManager from 'react-gtm-module';
import {phoneNumByEnv} from '@/components/utils/helper';
import env from '@/constants/env';
import {gtag} from 'ga-gtag';

type Props = {
  handleDone: ({key}) => void;
  aanvraag?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
declare const window: any;

const ContactForm = ({handleDone, aanvraag}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [selectBtn, setSelectBtn] = React.useState(null);
  const [firstName, setFirstName] = React.useState('');
  const [secondName, setSecondName] = React.useState('');
  const [initials, setInitials] = React.useState('');
  const leadInfo = state.lead;
  const [email, setEmail] = React.useState('');
  const [sessionId, setSessionId] = React.useState(null);
  const [phone, setPhone] = React.useState(phoneNumByEnv());
  const {translate} = useLocales();
  // Function to get the session ID
  const getSessionId = () =>
    new Promise((resolve) => gtag('get', env.GtagId, 'session_id', resolve));

  const getSessionIds = async () => {
      console.log('session_id_start', env.GtagId);

    const sessionIds = await getSessionId();
    console.log(sessionIds, 'session_id');
    setSessionId(sessionIds);
  };


  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // If already submitting or loading, prevent further execution
    if (loading || !validated) {
      setIsSubmitting(true);
      return;
    }

    setIsSubmitting(false);
    setLoading(true);

    const form = event.currentTarget;
    // Check form validity and stop if invalid
    if (!form.checkValidity()) {
      event.stopPropagation();
      setLoading(false);
      return;
    }

    // Extract query parameters from the URL
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries());
      const payload = {
        FirstName: firstName,
        LastName: secondName,
        Email: email,
        Phone: phone,
        Gender__c: selectBtn,
        Lead_Scoring__c: 'Cold',
        Agree_to_Privacy_Policy__c: checked,
        RequestedCreditAmount__c: store.getState().loan,
        Duration__c: Number(store.getState().year) * 12,
        Status: 'Incomplete',
        Initials__c: initials,
        Google_CID__c: getCookie('_ga'),
        Country: env.Country === 'NL' ? 'NLD' : env.Country || 'NLD',
        AccountId__c: leadInfo.AccountId__c,
        Company: `${firstName} ${secondName}`,
        COC_Number__c: leadInfo.COC_Number__c,
        Proposition__c: store.getState().selectedProduct.Label,
        SessionID__c: env.Country === 'NL' ? sessionId: null,
      };

      // Call the appropriate function based on the lead ID or store ID
      const action = params.lead_id ||
       store.getState().id ? updateLead : createLead;
      action(payload);
  };

  const createLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    authHttp.post('api/salesforce/lead/', {
      ...payload,
      ApplicationData__c: JSON.stringify(payload),
    })
    .then((res) => {
      store.dispatch(setID(res.data.id));
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
      handleDone({
        key: !aanvraag ? 2 : 1,
        });
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        store.dispatch(getUser(res.data.id));
        dataLayerHandler(res);
        setLoading(false);
  }).catch(() => {
    toast.error(translate('details_fail_submission'), {
      id: toastId,
    });
  })
  .finally(() => {
    setLoading(false);
  });
  };

  const dataLayerHandler = (res) => {
    const existingLoanInfo = JSON.parse(localStorage.getItem('loanInfo')) || {};
    const updatedDataLayer = {
      ...existingLoanInfo,
      mailadres: email,
      stap_aanvraag: 'Contactgegevens',
      lead_id: setID(res.data.id).payload,
    };
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: updatedDataLayer,
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify({
      dataLayer: updatedDataLayer,
    }));
  };

  const updateLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    handleDone({
      key: !aanvraag ? 2 : 1,
    });
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        setLoading(false);
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getSessionIds();
    setFirstName(leadInfo.FirstName);
    setSecondName(leadInfo.LastName);
    setEmail(leadInfo.Email);
    setSelectBtn(leadInfo.Gender__c);
    setPhone(leadInfo.Phone);
    setInitials(leadInfo.Initials__c);
    setChecked(leadInfo.Agree_to_Privacy_Policy__c);
}, []);

  React.useEffect(() => {
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Duration__c: store.getState().year,
      Initials__c: initials,
      Application_Submitted__c: 'New',
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    if (
      firstName &&
      secondName &&
      selectBtn &&
      email &&
      phone &&
      checked &&
      initials
    )
      setValidated(true);
    else setValidated(false);
  }, [firstName, secondName, selectBtn, checked, email, phone, initials]);

  return (
    <Card.Body className="desktop_form">
      <Form className="px-1">
        <Row className="mb-3 mt-3 align-items-center">
          <Form.Group controlId="validationCustom01">
            <ButtonGroup aria-label="Basic example">
              <Button
                className={(!selectBtn && isSubmitting) && 'invalid-border'}
                variant={
                  selectBtn === 'Female' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Female')}
              >
                {translate('mrs')}
              </Button>
              <Button
                className={(!selectBtn && isSubmitting) && 'invalid-border'}
                variant={
                  selectBtn === 'Male' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Male')}
              >
                {translate('mr')}
              </Button>
            </ButtonGroup>
          </Form.Group>
        </Row>
          <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setFirstName(e)}
          label={translate('f_name')}
          type="text"
          defaultValue={firstName}
          isSubmitting={isSubmitting}
          />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setInitials(e)}
          label={translate('initials_business')}
          type="initial"
          defaultValue={initials}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setSecondName(e)}
          label={translate('l_name')}
          type="text"
          defaultValue={secondName}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setEmail(e)}
          label={translate('email')}
          type="email"
          defaultValue={email}
          isSubmitting={isSubmitting}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setPhone(e)}
          label={translate('phone')}
          type="tel"
          defaultValue={phone}
          isSubmitting={isSubmitting}
        />
        <Form.Group controlId="validationCustom05"
          className='m-0 mt-4 pt-1 contact_form_footer'
        >
              <Form.Check
                required
                className={(!validated && isSubmitting) && 'invalid-checkbox'}
                type="checkbox"
                id="default-checkbox"
                checked={checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setChecked(e.target.checked)
                }
                label={
                  <>{translate('agree_TOC')}
                <a target='_blank' href='https://qredits.nl/service/algemene-voorwaarden.html?gad=1&gclid=CjwKCAjw3oqoBhAjEiwA_UaLtmHYAVW4h6SEFIA-BPQVRPD7AgFNg6KWLN-KItimts85QZUXPU0Z-hoCBrAQAvD_BwE&_gl=1*1e0p0im*_up*MQ..*_ga*MjY5NDQxNzU0LjE3MjkwNzY1NDE.*_ga_D37PVRKF58*MTcyOTA3NjU0MS4xLjEuMTcyOTA3NjU0MS4wLjAuMA..'
                              rel="noreferrer" style={{textDecoration: 'none'}}
                >
                                      &#128279;</a>
                  </>
                }
              />
            </Form.Group>
        <Row className="align-items-end m-0 mt-4 pt-1 contact_form_footer">
          <Col xs={7} md={7} className="col-10 p-0">
          </Col>
          <Col xs={5} md={5}
            className="justify-content-end text-end p-0 contact-submit-btn"
          >
            <div className="row m-0 justify-content-end">
              <button
                type="submit"
                className={`btn ${!validated || loading ?
                  'btn-disabled' : 'btn-success'}
                px-3 mobile_button`}
              onClick={handleSubmit}
              >
                {translate('next_step')}
              </button>
            </div>
                      </Col>
        </Row>
      </Form>
    </Card.Body>
  );
};

export default ContactForm;
